import { useContext } from 'react';

import { GooseListContext } from 'src/visualization/context/gooseContext/gooseContext';
import { useBuildObjectTree } from 'src/visualization/context/hooks/useBuildObjectTree';
import { useObjectLoader } from 'src/visualization/context/hooks/useObjectLoader/useObjectLoader';

export function BackgroundObjectLoader() {
    const { data } = useContext(GooseListContext);
    useObjectLoader();
    useBuildObjectTree(data.objects);
    return null;
}
