import type { ListedObject } from '@local/api-clients/dist/goose/enhancedGooseClient';

import type { TreeStructure, LoadedObject } from 'src/store/visualization/visualizationSlice.types';
import { getViewIdFromObjectId } from 'src/visualization/context/snapshots/generateSnapshot';

export function getSceneListItem(objectListItem: LoadedObject) {
    let id;
    let name;
    let schema;
    if ('object' in objectListItem) {
        id = objectListItem.object_id;
        name = objectListItem.object.name;
        schema = objectListItem.object.schema;
    } else {
        id = objectListItem.treeId;
        name = objectListItem.name;
        schema = objectListItem.schema ?? '';
    }

    const viewId = getViewIdFromObjectId(id, schema);

    return {
        id,
        viewId,
        name,
        schema,
    };
}

export function getObjectListItem(objectListItem: ListedObject | TreeStructure) {
    let id;
    let parentId;
    if ('object_id' in objectListItem) {
        id = objectListItem.object_id;
    } else {
        id = objectListItem.treeId;
        parentId = objectListItem.parentId;
    }

    const { name, schema } = objectListItem;

    return {
        id,
        name,
        schema,
        parentId,
    };
}
