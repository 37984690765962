import { TitleRow } from '@local/content-area';
import { RecycleBinEmptySvg } from '@local/svgs/dist/svg/RecycleBinEmptySvg';
import { Breadcrumb, BreadcrumbSegment } from '@local/web-design-system/dist/components/Breadcrumb';
import { EmptyState } from '@local/web-design-system/dist/components/EmptyState';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import Grid from '@mui/material/Grid';

import {
    RECYCLE_BIN_NO_CONTENT,
    RECYCLE_BIN_NO_CONTENT_DESC,
    RECYCLE_BIN_TITLE,
} from 'src/strings';
import { recycleBinBreadcrumb } from 'src/utils/breadcrumbs';

import { useStyles } from './RecycleBinPage.styles';

export function RecycleBinPage() {
    const { classes } = useStyles();

    setDocumentTitle(RECYCLE_BIN_TITLE);

    const segments: BreadcrumbSegment[] = [recycleBinBreadcrumb];

    return (
        <Grid container wrap="nowrap" direction="column">
            <Breadcrumb segments={segments} medium />
            <TitleRow title={RECYCLE_BIN_TITLE} />
            <Grid container direction="column" gap={1} className={classes.hoveringTable}>
                <Grid container flexGrow={1} alignItems="center" justifyContent="center">
                    <EmptyState
                        title={RECYCLE_BIN_NO_CONTENT}
                        message={RECYCLE_BIN_NO_CONTENT_DESC}
                        image={
                            <div className={classes.noObjectsImage}>
                                <RecycleBinEmptySvg />
                            </div>
                        }
                        classes={{ root: classes.noObjects }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
