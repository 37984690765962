import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import {
    ObjectTreeState,
    SelectionListState,
    SelectorTypeBoolean,
    SelectorTypeObjects,
    SelectorTypeObject,
    SelectorTypeObjectTree,
    SelectorTypeObjectTreeExpanded,
    SelectorTypeObjectTreeStructure,
    SelectorTypeString,
    SelectorTypeStringArray,
    VisualizationState,
} from './visualizationSlice.types';

export const initialSelectionState: SelectionListState = {
    selection: [],
    shiftSelection: [],
};

const initialObjectTreeState: ObjectTreeState = {
    treeState: {},
    expanded: {},
};

export const initialState: VisualizationState = {
    projectTree: {
        selected: initialSelectionState,
    },
    scenePanel: {
        selected: initialSelectionState,
    },
    loadedObjects: {},
    overlays: { scalebar: true, orientation: true },
    objectTree: initialObjectTreeState,
};

const visualizationState = (state: RootState): VisualizationState =>
    state.visualization ?? initialState;

export const scalebarVisible: SelectorTypeBoolean = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.overlays.scalebar,
);

export const orientationVisible: SelectorTypeBoolean = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.overlays.orientation,
);

export const loadedObjectsMap: SelectorTypeObjects = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.loadedObjects,
);

export const loadedObjectById = (objectId: string): SelectorTypeObject =>
    createSelector(
        visualizationState,
        (visualizationStateRoot) => visualizationStateRoot.loadedObjects[objectId],
    );

export const selectionListScenePanel: SelectorTypeStringArray = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.scenePanel.selected.selection,
);
export const shiftSelectionScenePanel: SelectorTypeStringArray = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.scenePanel.selected.shiftSelection,
);

export const lastSelectedScenePanel: SelectorTypeString = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.scenePanel.selected.lastSelected ?? '',
);

export const isSelectedInScenePanel = (objectId: string): SelectorTypeBoolean =>
    createSelector(selectionListScenePanel, (selectionList) => selectionList.includes(objectId));

export const selectionListProjectTree: SelectorTypeStringArray = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.projectTree.selected.selection,
);
export const shiftSelectionProjectTree: SelectorTypeStringArray = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.projectTree.selected.shiftSelection,
);

export const lastSelectedProjectTree: SelectorTypeString = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.projectTree.selected.lastSelected ?? '',
);

export const isSelectedInProjectTree = (objectId: string): SelectorTypeBoolean =>
    createSelector(selectionListProjectTree, (selectionList) => selectionList.includes(objectId));

export const getLoadedObjectSchemaByObjectId = (objectId: string): SelectorTypeString =>
    createSelector(loadedObjectById(objectId), (response) => {
        if (!response) return '';
        if ('object' in response) {
            return response.object.schema;
        }
        return response.schema ?? '';
    });

export const getLoadedObjectNameByObjectId = (objectId: string): SelectorTypeString =>
    createSelector(loadedObjectById(objectId), (response) => {
        if (!response) return '';
        if ('object' in response) {
            return response.object.name;
        }
        return response.name;
    });

export const getObjectTree: SelectorTypeObjectTree = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.objectTree,
);
export const getObjectTreeExpanded: SelectorTypeObjectTreeExpanded = createSelector(
    getObjectTree,
    (objectTree) => objectTree.expanded,
);
export const getObjectTreeExpandedById = (treeId: string): SelectorTypeBoolean =>
    createSelector(getObjectTreeExpanded, (expanded) => expanded[treeId] ?? false);

export const getTreeItemById = (treeId: string): SelectorTypeObjectTreeStructure =>
    createSelector(getObjectTree, (objectTree) => objectTree.treeState[treeId]);
