import { LinearProgressFaked } from '@local/web-design-system/dist/components/Progress/LinearProgressFaked';
import { PlusIcon } from '@local/web-design-system/dist/icons/Actions/PlusIcon';
import { RefreshIcon } from '@local/web-design-system/dist/icons/Actions/RefreshIcon';
import { ErrorIcon } from '@local/web-design-system/dist/icons/Alerts/ErrorIcon';
import { useTrace } from '@local/web-design-system/dist/utils/trace';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SchemaIcon } from 'src/pages/workspacePage/workspaceContent/SchemaIcon';
import { fileNameExtensionRemover } from 'src/pages/workspacePage/workspaceContent/utils';
import { store, useAppDispatch, useAppSelector } from 'src/store/store';
import {
    isSelectedInProjectTree,
    isSelectedInScenePanel,
    selectionListProjectTree,
} from 'src/store/visualization/selectors';
import { clearSelectedObjectsProjectTree } from 'src/store/visualization/visualizationSlice';
import { ID_PARAM } from 'src/strings';
import { useDrag } from 'src/visualization/context/hooks/useDragAndDrop';
import { useObjectManager } from 'src/visualization/context/hooks/useObjectManager/useObjectManager';
import { getObjectListItem } from 'src/visualization/utils/listItemUtils';

import { APPROXIMATE_MAX_LOAD_TIME, ERROR_MESSAGE } from './ObjectListItemControl.constants';
import { useStyles } from './ObjectListItemControl.styles';
import { ObjectListItemControlProps } from './ObjectListItemControl.types';

export function ObjectListItemControl({ objectListItem }: ObjectListItemControlProps) {
    const { classes } = useStyles();
    const applyTrace = useTrace('object-list-item');
    const dispatch = useAppDispatch();

    const { id: objectId, name, schema, parentId } = getObjectListItem(objectListItem);
    const { loadObject, isError, isLoading, isObjectLoaded } = useObjectManager(objectId);

    const [searchParams] = useSearchParams();
    const objectIdQueryParam = searchParams.get(ID_PARAM) ?? '';
    useEffect(() => {
        if (objectId === objectIdQueryParam || parentId === objectIdQueryParam) {
            loadObject(objectId);
        }
    }, []);

    const handleAddObject = () => {
        const selectedObjects = selectionListProjectTree(store.getState());
        if (selectedObjects.includes(objectId)) {
            selectedObjects.forEach((id) => {
                loadObject(id);
            });
        } else {
            loadObject(objectId);
        }
        dispatch(clearSelectedObjectsProjectTree({}));
    };
    const onKeyUp = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleAddObject();
        }
    };
    const handleFailedObject = () => {
        loadObject(objectId);
    };

    const { onClick, onDoubleClick, onDragStart } = useDrag(objectId);
    const isSelectedScenePanel = useAppSelector(isSelectedInScenePanel(objectId));
    const isSelectedProjectTree = useAppSelector(isSelectedInProjectTree(objectId));
    const isObjectOnPlot = isObjectLoaded(objectId);
    const Icon = SchemaIcon(schema);
    return (
        <Grid item container className={classes.root}>
            <Grid
                className={classnames(classes.background, {
                    [classes.backgroundPending]: isLoading,
                    [classes.backgroundClear]: isObjectOnPlot && !isError,
                    [classes.backgroundSelected]: isSelectedScenePanel,
                    [classes.backgroundSelectedForLoading]:
                        isSelectedProjectTree && (!isObjectOnPlot || isError),
                })}
            />
            <Grid
                item
                container
                className={classes.objectListItemControl}
                wrap="nowrap"
                alignItems="center"
                justifyContent="center"
            >
                <Grid
                    item
                    container
                    zeroMinWidth
                    className={classnames(classes.mainContainer, {
                        [classes.cursor]: !isObjectOnPlot,
                    })}
                    alignItems="center"
                    draggable
                    onClick={onClick}
                    onDragStart={onDragStart}
                    onDoubleClick={onDoubleClick}
                    onKeyUp={onKeyUp}
                    tabIndex={isObjectOnPlot ? undefined : 0}
                >
                    <Grid
                        item
                        container
                        xs
                        alignItems="center"
                        wrap="nowrap"
                        className={classes.nameContainer}
                        {...applyTrace(name)}
                    >
                        <Grid item className={classes.icon}>
                            <Icon />
                        </Grid>
                        <Grid item xs>
                            <Typography
                                variant="body2"
                                color={isError ? 'error' : 'inherit'}
                                className={classes.name}
                            >
                                {fileNameExtensionRemover(name)}
                            </Typography>
                        </Grid>
                    </Grid>
                    {isLoading && (
                        <Grid className={classes.progressContainer}>
                            <LinearProgressFaked
                                isLoading={isLoading}
                                milliseconds={APPROXIMATE_MAX_LOAD_TIME}
                                hideWhenNotLoading
                                classes={{ root: classes.loadingProgressContainer }}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid item className={classes.loadButtonContainer}>
                    {!isError && !isObjectOnPlot && (
                        <IconButton
                            onClick={handleAddObject}
                            className={classnames(classes.loadIconButton)}
                        >
                            <PlusIcon fontSize="inherit" />
                        </IconButton>
                    )}
                    {isError && (
                        <IconButton
                            onClick={handleFailedObject}
                            className={classnames(classes.loadIconButton)}
                        >
                            <RefreshIcon fontSize="inherit" />
                        </IconButton>
                    )}
                </Grid>
            </Grid>

            {isError && (isSelectedProjectTree || isSelectedScenePanel) && (
                <Grid container alignItems="center" zIndex={1}>
                    <Grid item className={classes.errorIcon}>
                        <ErrorIcon fontSize="small" />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="caption" color="error">
                            {ERROR_MESSAGE}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}
