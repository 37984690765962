import { common } from '@local/web-design-system/dist/styles/common';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        contentsContainer: {
            ...common(theme).thinScrollBar,
            overflowY: 'auto',
            overflowX: 'hidden',
            padding: theme.spacing(0.5),
        },
        folderContainer: {
            margin: theme.spacing(0.5, 0),
        },
    }) as const;

export const useStyles = makeStyles()(styles);
