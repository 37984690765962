import { useTrace } from '@local/web-design-system/dist/utils/trace';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useAppSelector } from 'src/store/store';
import { loadedObjectsMap } from 'src/store/visualization/selectors';

import { SceneListItemControl } from './SceneListItemControl/SceneListItemControl';
import { EMPTY_STATE_MESSAGE, PANEL_HEADER } from './ScenePanel.constants';
import { useStyles } from './ScenePanel.styles';

export function ScenePanel() {
    const { classes } = useStyles();

    const loadedGooseObjectsMap = useAppSelector(loadedObjectsMap);
    const hasObjects = Object.keys(loadedGooseObjectsMap).length > 0;
    return (
        <Grid container item direction="column" className={classes.root}>
            <PanelHeader />
            <Grid
                container
                item
                xs
                direction="column"
                wrap="nowrap"
                className={classes.objectsList}
            >
                {!hasObjects && <EmptyStateMessage />}
                {Object.values(loadedGooseObjectsMap).map((loadedObject) => {
                    let key = '';
                    if ('object_id' in loadedObject) {
                        key = loadedObject.object_id;
                    } else {
                        key = loadedObject.treeId;
                    }
                    return <SceneListItemControl key={key} objectListItem={loadedObject} />;
                })}
            </Grid>
        </Grid>
    );
}

function PanelHeader() {
    const { classes } = useStyles();
    const applyTrace = useTrace('my-scene-panel-header');

    return (
        <Grid container alignItems="center" justifyContent="space-between">
            <Typography className={classes.panelHeader}>{PANEL_HEADER}</Typography>
            <Grid {...applyTrace()} />
        </Grid>
    );
}

function EmptyStateMessage() {
    const { classes } = useStyles();

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={classes.emptyStateMessageContainer}
        >
            <Typography align="center" className={classes.emptyStateMessage}>
                {EMPTY_STATE_MESSAGE}
            </Typography>
        </Grid>
    );
}
