import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const styles = (theme: Theme) =>
    ({
        root: {
            position: 'relative',
            padding: theme.spacing(0, 1),
        },
        objectListItemControl: {
            zIndex: 2,
            minHeight: theme.spacing(3),
            backgroundColor: 'transparent',
        },
        background: {
            backgroundColor: theme.palette.grey[50],
            position: 'absolute',
            height: '100%',
            width: '200vw !important',
            left: '-100vw !important',
            zIndex: 0,
        },
        backgroundPending: {
            backgroundColor: theme.palette.grey[50],
        },
        backgroundClear: {
            backgroundColor: 'transparent',
        },
        backgroundSelected: {
            backgroundColor: theme.palette.grey[200],
        },
        backgroundSelectedForLoading: {
            backgroundColor: theme.palette.grey[300],
        },
        loadButtonContainer: {
            height: '100%',
        },
        loadIconButton: {
            height: '100%',
            width: theme.spacing(3.5),
            padding: 0,
            fontSize: '14px',
            color: theme.palette.primary.main,
        },
        mainContainer: {
            height: '100%',
            color: theme.palette.grey[700],
            position: 'relative',
            border: '1px solid transparent',
            '&:focus': {
                outline: 'none',
                border: `1px solid ${theme.palette.grey[300]}`,
            },
        },
        mainContainerOutlined: {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        nameContainer: {
            paddingLeft: theme.spacing(0.5),
        },
        icon: {
            fontSize: '15px',
            minWidth: theme.spacing(1.875),
            paddingTop: theme.spacing(0.5),
        },
        name: {
            paddingTop: theme.spacing(0.25),
            paddingLeft: theme.spacing(),
            fontSize: '13px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            alignSelf: 'center',
        },
        progressContainer: {
            width: '100%',
        },
        loadingProgressContainer: {
            padding: theme.spacing(0.5, 0),
        },
        errorIcon: {
            color: theme.palette.error.main,
            margin: theme.spacing(0.875),
            marginRight: theme.spacing(0.75),
            width: theme.spacing(3),
        },
        cursor: {
            cursor: 'pointer',
        },
    }) as const;

export const useStyles = makeStyles()(styles);
